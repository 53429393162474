<template>
  <div  class="px-15px px-lg-25px">
    
     <div class="card">
      <div class="card-header">
          <h5 class="mb-0 h6">{{ $t('chan-pin-cha-xun') }}</h5>
      </div>
      <div class="card-body">
        <table class="table mb-0" cellspacing="0" width="100%">
          <thead>
            <tr>
              <th data-breakpoints="lg">#</th>
              <th>{{ $t('yong-hu-ming') }}</th>
              <th>{{ $t('chan-pin-ming-cheng') }}</th>
              <th data-breakpoints="lg">{{ $t('wen-ti') }}</th>
              <th data-breakpoints="lg">{{ $t('hui-fu') }}</th>
              <th>{{ $t('zhuang-tai') }}</th>
              <th class="text-right">{{ $t('cao-zuo') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in tableData" :key="i">
              <td>{{ i + 1 }}</td>
              <td>{{ item.username }}</td>
              <td>{{ item.name }}</td>
              <td>{{ item.question }}</td>
              <td>{{ item.reply }}</td>
              <td>
                <span class="badge badge-inline" :class="item.status == 1 ? 'badge-warning' : 'badge-success'">
                    {{ item.status == 1 ? '未回复' : '已回复' }}
                </span>
              </td>
              <td class="text-right">
                <a class="btn btn-soft-primary btn-icon btn-circle btn-sm"  :title="$t('cha-kan')" @click="toDetail(item)">
                  <i class="las la-eye"></i>
                </a>
              </td>
            </tr>
          </tbody>
        </table>
        <div class="aiz-pagination">
          <pageBar :totalPage="56" :page="51"></pageBar>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import pageBar from '@/components/page'
export default {
  components: {
    pageBar
  },
  data() {
    return {
      hasPackage: true,
      packageUrl: require('../../../assets/imgs/package.png'),
      tableData: [],
    }
  },
  methods: {
    toDetail(data) {
      this.$router.push({
        name: 'productQueryDetail'
      })
    }
  }
}
</script>